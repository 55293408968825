import React, { useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import JobExperienceList from "../components/jobExperiences";
import Animation from "../components/animation";
/**
 * Experience component representing the page displaying job experiences.
 *
 * This component lists job experiences and their details, such as job titles,
 * durations, and descriptions. It includes a navigation bar and a footer.
 *
 * @component
 */
function Experience() {
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);

  const handleNavbarToggle = (collapsed) => {
    setNavbarCollapsed(collapsed);
  };

  // Define an array of job experiences
  const jobExperiences = [
    {
      jobTitle: "Junior Front End Developer | Mark Harrison LTD",
      duration: "Feb 2023 - June 2023",
      description: (
        <div>
          <p>As a Junior Front End Developer at Mark Harrison LTD, I collaborated closely with the backend team to create a user-friendly web application interface. </p>
          <p>In addition to my own tasks, I provided support to team members who needed assistance and took the lead in morning standup meetings to keep everyone informed about our progress.</p>
          <br />
          <p>Key achievements:</p>
          <ul>
            <li>- Gained an understanding in PHP, HTML, and React JS.</li>
            <li>- Collaborated with the backend team to build a web application to automate the process of creating a docker project.</li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <>
    {/* Include the navigation bar component */}
    <Navbar onToggle={handleNavbarToggle} />
      {/* Include the footer component */}
      <Footer />
      <Animation>
        <div
          className={`${
            isNavbarCollapsed
              ? "opacity-100"
              : "blur-md"
          } transition-all duration-500 ease-in-out`}
        >
        {/* Main heading for the experience page */}
        <h1 className='text-5xl font-bold text-white text-center pt-10 absolute inset-x-0 top-0 z-10'>Experience</h1>

        {/* Include the JobExperienceList component with the defined job experiences */}
        <JobExperienceList jobExperiences={jobExperiences} />

      </div>
    </Animation>
    </>
  );
}

export default Experience;
