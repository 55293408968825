/**
 * Button component representing a clickable button with an icon and label.
 *
 * This component displays a button with an optional icon and label. It supports click events.
 *
 * @component
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.id - The HTML id attribute for the button.
 * @param {function} props.onClick - The function to be executed when the button is clicked.
 * @param {string} props.src - The source URL for the button's icon.
 * @param {string} props.label - The label or text displayed on the button.
 */
function Button(props) {
  return (
    <button 
      className='object-fill h-auto w-auto transform duration-300 scale-90 hover:scale-95 text-2xl text-white transition-all hover:text-3xl' 
      id={props.id}
      onClick={props.onClick}
    > 
      <img className="max-h-14" src={props.src} alt=""/>
      {props.label}
    </button>
  );
}

export default Button;
