import React, { useState } from "react";
import Navbar from "../components/navbar";
import Textbox from "../components/textbox";
import Footer from "../components/footer";
import Animation from "../components/animation";

function Home() {
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);

  const handleNavbarToggle = (collapsed) => {
    setNavbarCollapsed(collapsed);
  };

  return (
    <>
      <Navbar onToggle={handleNavbarToggle} />
      <div
        className={`${
          isNavbarCollapsed
            ? "opacity-100"
            : "blur-md"
        } transition-all duration-500 ease-in-out`}
      >
        <Footer />
        <Animation>
          <div>
            <h1 className='text-5xl font-bold text-white text-center pt-10 absolute inset-x-0 top-0 z-10'>Welcome</h1>
            <Textbox text="I'm Steven Pereira, an IT enthusiast with a background in database management, web development, and backend development. I've dedicated three years to studying IT and have been drawn to technology throughout my life. My journey in IT has been both fulfilling and educational, and I'm eager to continue expanding my skills and applying them to real-world challenges. Explore my portfolio to see my work and experiences, and feel free to reach out if you'd like to connect or learn more. Thank you for visiting!" />
          </div>
        </Animation>
      </div>
    </>
  );
}

export default Home;
