import React from 'react';

/**
 * Footer component representing the footer section of the application.
 *
 * This component displays a footer with copyright information and a link to the author's website.
 *
 * @component
 */
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="fixed bottom-0 w-full bg-dark text-white text-center py-2">
      <hr className="my-4 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-4" />
      <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© {currentYear} <a href="https://www.stevenpereira.dev" className="hover:underline">Steven Pereira</a></span>
    </footer>
  );
};

export default Footer;
