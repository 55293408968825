import React, { useState } from 'react';
import home from '../img/home.png';
import project from '../img/projects.png';
import experience from '../img/experience.png';
import github from '../img/github.png';
import stack from '../img/stack.png';
import email from '../img/email.png';
import linkedin from '../img/linkedin.png';
import Button from './button';
/**
 * Navbar component representing the application's navigation bar.
 *
 * This component includes navigation links, collapsible menu, and links to external profiles.
 *
 * @component
 */
function Navbar({ onToggle }) {
  // State to manage the collapse/expand behavior of the menu
  const [isCollapsed, setCollapsed] = useState(true);
  console.log(isCollapsed)
  // Function to toggle the collapse/expand state of the menu
  const toggleCollapse = () => {
    setCollapsed(!isCollapsed);
    if (onToggle) {
      onToggle(!isCollapsed);
    }
  };
  // Function to handle redirection to external URLs
  const handleRedirect = (url) => {
    window.location.href = url;
    console.log("clicked button", url);
  };
  return (
    <div className="fixed h-screen flex flex-col justify-center items-start p-2 z-50">
      <div className='border-l-4 border-white p-2 overflow-hidden'>
        <button
          onClick={toggleCollapse}
          className={`text-4xl text-white block transform transition-transform duration-500 ${
            isCollapsed ? 'rotate-180' : 'rotate-0'
          }`}
        >
          {isCollapsed ? '>' : '>'}
        </button>
        <div className={`border-2 rounded-lg bg-gradient-to-r to-gray-900 from-stone-700 space-y-4 transform transition-all duration-500 ${isCollapsed ? '-translate-x-full opacity-0 invisible' : 'translate-x-0 opacity-100'}`}>
        <div>
            <Button id="home" src={home} onClick={() => handleRedirect('https://stevenpereira.dev/#/')} />
          </div>
          <div>
            <Button id="project" src={project} onClick={() => handleRedirect('https://stevenpereira.dev/#/projects')} />
          </div>
          <div>
            <Button id="experience" src={experience} onClick={() => handleRedirect('https://stevenpereira.dev/#/experience')} />
          </div>
          <div>
            <Button id="github" src={github} onClick={() => handleRedirect('https://github.com/keedux')} />
          </div>
          <div>
            <Button id="stackoverflow" src={stack} onClick={() => handleRedirect('https://stackoverflow.com/users/21581480')} />
          </div>
          <div>
            <Button id="linkedin" src={linkedin} onClick={() => handleRedirect('https://www.linkedin.com/in/steven-pereira-76a47b213')} />
          </div>
          <div>
            <Button id="email" src={email} onClick={() => handleRedirect('mailto:stevenpereira48@gmail.com')} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default Navbar;