import React from 'react';

/**
 * JobExperienceList component representing a list of job experiences.
 *
 * This component takes a list of job experiences and displays them in a structured format.
 *
 * @component
 *
 * @param {object} props - The properties passed to the component.
 * @param {Array} props.jobExperiences - An array of job experiences to display.
 */
const JobExperienceList = ({ jobExperiences }) => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="">
        {jobExperiences.map((experience, index) => (
          <div key={index} className="
          border-2 p-4 rounded shadow overflow-auto
          xsm:w-[200px] xsm:h-[525px]
          sm:w-[400px] sm:h-[525px]
          md:w-[600px] md:h-[400px]
          lg:w-[800px] lg:h-[400px]
          ">
            <h2 className="text-xl text-neutral-400 font-semibold mb-2">{experience.jobTitle}</h2>
            <p className="text-gray-300">{experience.duration}</p>
            <div className="mt-2 text-neutral-300 font-bold">{experience.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default JobExperienceList;
