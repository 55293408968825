import { HashRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Home from './pages/home.js';
import Projects from './pages/projects';
import Experience from './pages/experience';

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/experience" element={<Experience />} />
        </Routes>
      </HashRouter>
    </>
  );
}

export default App;
