import React from 'react';

/**
 * Textbox component representing a text container.
 *
 * This component displays a text container with the provided text content.
 *
 * @component
 *
 * @param {object} props - The properties passed to the component.
 * @param {string} props.text - The text content to display in the textbox.
 */
function Textbox(props) {
  return (
    <div className='flex items-center justify-center h-screen'>
      <div className='
      text-center
      text-white
      xsm:max-w-[250px]
      sm:max-w-[450px]
      md:max-w-[650px]
      lg:max-w-[850px]
      '>
        <hr className="border-white border-2 my-4" />
        {props.text}
        <hr className="border-white border-2 my-4" />
      </div>
    </div>
  );
}

export default Textbox;
