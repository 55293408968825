import React, { useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import GitHubRepoList from "../components/githubrepo";
import Animation from "../components/animation";
/**
 * Project component representing the page displaying a list of GitHub repositories.
 *
 * This component displays a list of GitHub repositories and includes a navigation bar and a footer.
 *
 * @component
 */
function Project() {
  const [isNavbarCollapsed, setNavbarCollapsed] = useState(true);

  const handleNavbarToggle = (collapsed) => {
    setNavbarCollapsed(collapsed);
  };

  return (
    <>
      {/* Include the navigation bar component */}
      <Navbar onToggle={handleNavbarToggle} />
      {/* Include the footer component */}
      <Footer />
      <Animation>
        <div
          className={`${
            isNavbarCollapsed
              ? "opacity-100"
              : "blur-md"
          } transition-all duration-500 ease-in-out`}
        >
          {/* Main heading for the projects page */}
          <h1 className='text-5xl font-bold text-white text-center pt-10 absolute inset-x-0 top-0 z-10'>Projects</h1>

          {/* Include the GitHubRepoList component to display the list of GitHub repositories */}
          <GitHubRepoList />

        </div>
      </Animation>
    </>
  );
}

export default Project;
