import React, { useEffect, useState } from 'react';
import '../App.css';

function GitHubRepoList() {
  const [repos, setRepos] = useState([]);
  const [languages, setLanguages] = useState({});
  const [selectedRepoIndex, setSelectedRepoIndex] = useState(0);

  useEffect(() => {
    fetch('https://api.github.com/users/Keedux/repos')
      .then(response => response.json())
      .then(data => {
        setRepos(data);
      })
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    async function fetchLanguagesForRepo(repo) {
      try {
        const response = await fetch(repo.languages_url);
        const data = await response.json();
        setLanguages(prevLanguages => ({
          ...prevLanguages,
          [repo.id]: Object.keys(data),
        }));
      } catch (error) {
        console.error(error);
      }
    }

    repos.forEach(repo => {
      fetchLanguagesForRepo(repo);
    });
  }, [repos]);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedRepoIndex(prevIndex => (prevIndex + 1) % repos.length);
    }, 5000); // Change slide every 5 seconds

    return () => {
      clearInterval(interval);
    };
  }, [repos]);

  const handleNextRepo = () => {
    setSelectedRepoIndex(prevIndex => (prevIndex + 1) % repos.length);
  };

  const repo = repos[selectedRepoIndex];
  const repoName = repo
    ? repo.name.replace(/-/g, ' ').toLowerCase().replace(/\b\w/g, c => c.toUpperCase())
    : '';

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="p-4 border-2 border-dashed border-neutral-500 bg-neutral-800 rounded-lg">
        <div
          className="
          h-[400px]
          xsm:w-[200px] xsm:h-[450px]
          min-[550px]:w-[400px] 
          sm:w-[550px]  
          md:w-[750px]
        "
        >
          <div className="flex justify-center h-screen">
            {repos.length > 0 && (
              <div key={repo.id} className="p-2 my-3">
                <a href={repo.html_url}>
                  <h1 className="text-white font-bold pb-3 text-center text-2xl transform duration-300 hover:text-neutral-300 transition-all">
                    {repoName}
                  </h1>
                </a>
                <div className="
                border-2 border-neutral-700 shadow-2xl border-dashed rounded-md bg-neutral-300 p-4
                xsm:max-h-[325px] overflow-auto
                ">
                  <p className="text-black font-bold text-center">{repo.description}</p>
                  <div className="flex justify-center flex-wrap">
                    {languages[repo.id] &&
                      languages[repo.id].map((language, languageIndex) => (
                        <span
                          key={languageIndex}
                          className="bg-neutral-400 border-2 border-neutral-500 shadow-md text-black text-center rounded-md px-2 py-1 m-1"
                        >
                          {language}
                        </span>
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-end">
        </div>
        <div className="flex justify-center mt-4">
          {repos.map((_, index) => (
            <button onClick={handleNextRepo}
              key={index}
              className={`h-2 w-2 mx-1 rounded-full ${selectedRepoIndex === index ? 'bg-white' : 'bg-gray-500'}`}
            ></button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default GitHubRepoList;
